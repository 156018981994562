import Image from 'next/image'

import { Button } from '../../components/button/button'

import CSS from './hero-brand.module.css'

export function HeroBrand ({ children, theme }) {
    return (
        
        <section className={`${CSS.layout} ${ theme ? theme : `bg-primary`}`} >

            <div className={CSS.heroImage}>
                <Image src={`/assets/img/homepage/hero-2025-b.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </div>

            <div className={`${CSS.panel} block`}>
                <p className={CSS.theme}>Connect Invest Transform</p>
                <p className='fs-4 fw-600'>17-19 June 2025</p>
                <p className='fs-4'>The Westin Cape Town, South Africa</p>

                <p className='mt-md'><Button href={'https://www.eventbooking.uk.com/AHIF-2025?_ga=2.137623475.2013761134.1731343042-747783354.1720527023'} decal={'fs-6 fw-600'}>Purchase Pass</Button></p>
            </div>

            <section className={CSS.content}>
                <p className="fs-5">The Africa Hospitality Investment Forum (AHIF) is the leading hospitality investment forum on the African continent. The event connects business leaders to enable deals that drive investment and development into tourism and hotel infrastructure.</p>
                <div className="mt-sm button-grid">
                    <Button href={'/partners'} outline decal={'fw-600'}>Become a Sponsor</Button>
                    <Button href={'/download-prospectus'} outline decal={'fw-600'}>Download Prospectus</Button> 
                </div>
            </section>

            <section className={CSS.graphic}>
                <Image src={`/assets/img/homepage/brand-pattern-horizontal.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </section>

        </section>

    )
}